<template>
  <div class="content">
    <div class="m-card my-card">
      <div class="mc-top">
        <div class="mc-top-title">
          <span class="mc-top-txt">我的证件</span>
        </div>
      </div>
      <div style="padding: 50px">
        <Row
          :gutter="46"
          v-if="(list && list.length) || (actList && actList.length)"
        >
          <Col
            :key="index"
            span="12"
            v-for="(item, index) in list"
          >
            <div class="card-box border_boxshow">
              <div class="cb-title">
                <span class="cb-title-left">{{ item.title }}</span>
                <span class="cb-title-btn">发送至手机</span>
              </div>
              <div class="cb-info">
                <p>有效时间：{{ item.validTime }}</p>
                <p>活动地址：{{ item.address }}</p>
              </div>
              <div class="cb-code">
                <p>电子编号：{{ item.code }}</p>
                <div class="cb-code-display"></div>
              </div>
              <div style="width: 100%">
                <swiper
                  :options="swiperOption"
                  class="swiper"
                >
                  <swiper-slide class="swiper_item">
                    <div class="swiper-box">
                      <canvas
                        :id="'tiao_code' + index"
                        class="barcode"
                      ></canvas>
                    </div>
                  </swiper-slide>
                  <swiper-slide class="swiper_item">
                    <div class="swiper-box swiper-box2">
                      <div
                        :id="'my_qrcode' + index"
                        class="barcode_img"
                        style="text-align: center"
                      ></div>
                    </div>
                  </swiper-slide>
                  <div
                    class="swiper-pagination"
                    slot="pagination"
                  ></div>
                </swiper>
              </div>
            </div>
          </Col>
          <Col
            :key="item.id"
            span="12"
            v-for="item in actList"
          >
            <div class="card-box act_card_wrap">
              <div class="act_card">
                <div class="cb-title">
                  {{ item.activity.title }}
                  <span class="cb-title-btn">发送至手机</span>
                </div>
                <div class="act_info">
                  <p class="flex-p">
                    <span>票种：{{ item.ticketName }}</span>
                    <span>票数：{{ item.number }}</span>
                  </p>
                  <p class="flex-p">
                    <span>联系人：{{ item.activityReg.fullName }}</span>
                    <span>手机：{{ item.activityReg.phoneNumber }}</span>
                  </p>
                  <p v-if="item.validStartAt && item.validEndAt">有效时间：{{ item.validTime }}</p>
                  <p v-if="item.activity.address">活动地址：{{ item.activity.address }}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div
          class="u-empty"
          v-else
        >暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "layout",
  data () {
    return {
      list: [],
      actList: [],
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created () {
    this.getPapers();
    this.getActPapers();
  },
  mounted () { },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    async getPapers () {
      let query = `
        query{
          memberCertificateQuery{
            byUserId(exhibitionId:"${this.exhibitionId}",userId:"${this.user.userId}"){
                address
                city
                code
                country
                county
                createAt
                exhibitionId
                id
                title
                validStartAt
                validEndAt
                metadata
              }
            }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(
        JSON.stringify(data.data.memberCertificateQuery.byUserId)
      );
      res.forEach((e) => {
        e.validTime =
          moment(e.validStartAt).format("YYYY/MM/DD") +
          "~" +
          moment(e.validEndAt).format("MM/DD");
      });
      this.list = res;
      this.$nextTick(() => {
        this.list.forEach((el, index) => {
          this.JsBarcode(el.code, index);
        });
      });
      console.log("我的证件", this.list);
    },
    async getActPapers () {
      let query = `
        query($query:QueryInput!){
          activityTicketQuery{
            query(query:$query){
               items{
                activity{
                  address
                  title
                }
                activityReg{
                  fullName
                  phoneNumber
                }
                number
                ticketName
                validEndAt
                validStartAt
              }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal2: {
          n: "memberId",
          v: this.user.id,
        },
      };
      let opt = {
        query: query,
        variables: {
          query: {
            page: 1,
            size: 50,
            where: JSON.stringify(where),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      console.log("活动票", data);
      let res = JSON.parse(JSON.stringify(data.data.activityTicketQuery.query));
      res.items.forEach((e) => {
        e.validTime =
          moment(e.validStartAt).format("YYYY/MM/DD") +
          "~" +
          moment(e.validEndAt).format("MM/DD");
      });
      this.actList = res.items;
    },
    JsBarcode (code, index) {
      $("#tiao_code" + index).JsBarcode(code, {}); //displayValue: false,是否在条形码下方显示文字
      // 加载二维码 reqBody = null;
      const qrCode = new QRCode("my_qrcode" + index, {
        width: 130, // 设置宽度
        height: 130, // 设置高度
        text: code,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    creatQrCode (ref) {
      let url = "1231231231";
      this.qrcode = new QRCode(this.$refs[ref], {
        text: url, // 需要转换为二维码的内容
        width: 130,
        height: 130,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
  destroyed () { },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
  .m-card {
    border: solid 1px #ededed;
    min-height: 600px;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}
.my-card {
  .card-box {
    &.border_boxshow,
    .act_card {
      background-color: #fff;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
      border: solid 1px rgba(24, 144, 255, 0.66);
      @include border_color_hover(#1890ff);
      border-radius: 5px;
    }
    .act_card {
      width: 100%;
      height: 220px;
      .act_info {
        padding: 16px 16px;
        color: #666;
        p {
          line-height: 2;
          &.flex-p {
            span {
              display: inline-block;
              line-height: initial;
              width: 50%;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    &.act_card_wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;
    height: 450px;

    .cb {
      &-title {
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 12px;
        border-bottom: solid 1px#d9d9d9;
        &-btn {
          padding: 6px 18px;
          @include background_color(#1890ff);
          color: #fff;
          cursor: pointer;
          border-radius: 3px;
          display: inline-block;
          width: 106px;
        }
        &-left {
          width: calc(100% - 106px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &-info {
        height: 90px;
        display: flex;
        align-content: center;
        font-size: 14px;
        color: #666;
        border-bottom: 1px dashed #fb8e9a;
        flex-wrap: wrap;
        p {
          width: 100%;
          line-height: 2;
          padding: 0 16px;
        }
      }
      &-code {
        p {
          font-size: 15px;
          color: #333;
          padding: 40px 0 26px;
          text-align: center;
        }
      }
    }
  }
}

.swiper {
  height: 170px;
  .swiper_item {
    // width: 100%!important;
  }
  &-box {
    height: 156px;
    margin: 0 auto;
    text-align: center;
  }
  .barcode {
    margin: 0 auto;
    border: solid 1px rgba(123, 123, 123, 0.4);
  }
  .barcode_img {
    margin-left: -18px;
    img {
      border: solid 1px rgba(123, 123, 123, 0.4);
    }
  }
  ::v-deep {
    // .swiper-slide {
    //   width: 100% !important;
    // }
    .swiper-pagination {
      bottom: -2px !important;
    }
    .swiper-pagination-bullet-active {
      @include background_color(#1890ff);
    }
  }
}
</style> 



<style lang="scss">
.swiper-box2 {
  width: 451px;
  img {
    margin: 0 auto;
  }
}
</style>